import { Row, Col, Label } from "reactstrap";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Input,
  FormText,
  Nav,
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { useDebounce } from "../../../helpers/utils";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { PaginationProvider } from "react-bootstrap-table2-paginator";
import { isIterableArray, getPaginationArray } from "../../../helpers/utils";
import csvtojson from "csvtojson";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap";
import { createResident, deleteResident } from "../../../data/dataUpdate";
import AddressMap from "../map/AddressMap";
import Select from "react-select";
import {
  fetchCustomers,
  fetchCustomerBuildings,
} from "../../../data/dataFetch";
import * as XLSX from "xlsx";

const Residents = ({ residents, setResidents, onResidentsChange }) => {
  const [columns, setColumns] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState();
  const debouncedFilter = useDebounce(filter, 300);
  const history = useHistory();
  const [importCSV, setImportCsv] = useState(false);
  const [activeTab, setActiveTab] = useState("list");
  const [importBuilding, setImportBuilding] = useState();
  const [importDelimiter, setImportDelimiter] = useState(",");
  const [importCustomer, setImportCustomer] = useState();
  const [importMessage, setImportMessage] = useState();
  const [customers, setCustomers] = useState(null);
  const [customerBuildings, setCustomerBuildings] = useState(null);
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [buildingFilter, setBuildingFilter] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const uniqueBuildings = [
    ...new Set((residents || []).map((item) => item.building_name)),
  ];

  const handleBuildingFilterChange = (event) => {
    console.log("buidlingFilter", event.target.value);
    setBuildingFilter(event.target.value);
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle caret className="btn btn-success mb-2">
            Eksporter til fil
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => props.onExport()}>CSV</DropdownItem>
            <DropdownItem
              onClick={() => exportToExcel(filtered, "beboere.xlsx")}
            >
              Excel
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <div>
          <Button
            className="float-right mb-2"
            color="success"
            onClick={() => {
              setImportCsv(true);
            }}
          >
            Importer fra CSV
          </Button>
        </div>
      </>
    );
  };

  useEffect(() => {
    let columns = [
      {
        dataField: "building_name",
        text: "Bygning",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
        headerFormatter: (column, colIndex, { sortElement }) => {
          return (
            <div className="d-flex flex-column">
              <span>{column.text}</span>
              <select
                id="selectBuilding"
                className="mt-1 rounded-pill"
                onChange={handleBuildingFilterChange}
              >
                <option value="">Alle bygninger</option>
                {uniqueBuildings.map((building) => (
                  <option key={building} value={building}>
                    {building}
                  </option>
                ))}
              </select>
            </div>
          );
        },
      },
      {
        dataField: "first_name",
        text: "Fornavn",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
      },
      {
        dataField: "last_name",
        text: "Etternavn",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
      },
      {
        dataField: "login_id",
        text: "login",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
      },
      {
        dataField: "box.box_address",
        text: "Postkasse",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
      },
      {
        dataField: "email",
        text: "Epost",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
      },
      {
        dataField: "phone",
        text: "Telefone",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
      },
      {
        dataField: "pairing_code",
        text: "Paringskode",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
      },
    ];

    setColumns(columns);

    // eslint-disable-next-line
  }, [residents]);

  const filterByBuilding = (data, filter) => {
    if (!filter) return data;
    return data.filter((item) => item.building_name === filter);
  };

  const filterBySearch = (data, search, columns) => {
    if (!search) return data;
    const searchValue = search.toLowerCase();
    const columnNames = columns.map((c) => c.dataField);
    return data.filter((item) => {
      const values = Object.entries(item).filter(([key]) =>
        columnNames.includes(key)
      );
      return values.some(([key, value]) =>
        String(value).toLowerCase().includes(searchValue)
      );
    });
  };

  useEffect(() => {
    let allFiltered = filterByBuilding(residents, buildingFilter);
    allFiltered = filterBySearch(allFiltered, debouncedFilter, columns);
    setFiltered(allFiltered);
  }, [residents, debouncedFilter, columns, buildingFilter]);

  useEffect(() => {
    const loadCustomers = async () => {
      setCustomers(await fetchCustomers());
    };
    loadCustomers();
  }, []);

  const handleNextPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page + 1);
    };

  const handlePrevPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page - 1);
    };

  if (!residents || !isIterableArray(columns)) {
    return null;
  }

  const loadCustomerBuildings = async (customerId) => {
    setCustomerBuildings(await fetchCustomerBuildings(customerId));
  };
  const handleImportClick = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    var options = { delimiter: importDelimiter };
    console.log("importDelimiter", importDelimiter);
    reader.onload = async () => {
      const csvData = reader.result;
      const jsonArray = await csvtojson(options).fromString(csvData);
      var alertText = "";
      const newResidents = [];
      for (const element of jsonArray) {
        var resident = JSON.parse(JSON.stringify(element));
        resident.building_id = importBuilding;
        resident.customer_id = importCustomer;
        console.log("import resident", resident);
        const result = await createResident(resident, true);
        if (result) {
          alertText += `\n beboer ${resident.Fornavn ?? resident.first_name}  ${
            resident.Etternavn ?? resident.last_name
          } er lagt til`;
          newResidents.push(result);
        } else {
          alertText += `\n feil ved import av beboer`;
        }
      }
      setImportMessage(alertText);
      setResidents([...residents, ...newResidents]);
      onResidentsChange();
    };
    reader.readAsText(file);
  };

  const handleDelete = () => {
    const selectedResidents = residents.filter((resident) =>
      selected.includes(resident.resident_id)
    );
    const SelectedNames = selectedResidents
      .map((resident) => resident.first_name + " " + resident.last_name)
      .join("\n");
    setModalMessage(
      `Bekrefte sletting ${selected.length} beboere?\n${SelectedNames}`
    );
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    // Implement your delete logic here
    for (const residentId of selected) {
      await deleteResident(residentId);
    }

    await onResidentsChange();
    setShowModal(false);
  };

  const tableOptions = {
    onClick: (e, row, rowIndex) => {
      if (e.target.type !== "checkbox") {
        history.push(`/resident/${row.resident_id}`);
      }
    },
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelected([...selected, row.resident_id]);
      } else {
        setSelected(selected.filter((x) => x !== row.resident_id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelected(rows.map((row) => row.resident_id));
      } else {
        setSelected([]);
      }
    },
  };

  const exportToCSV = (csvData, fileName) => {
    // Add BOM to the beginning of the CSV string
    const BOM = "\uFEFF"; // Byte Order Mark for UTF-8
    const csvContent = BOM + csvData; // Prepend BOM to CSV data

    // Create a Blob with UTF-8 encoding
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filterDataForExport = (data, columns) => {
    const columnFields = columns.map((col) => col.dataField);
    return data.map((row) => {
      const filteredRow = {};
      columnFields.forEach((field) => {
        filteredRow[field] = row[field];
      });
      return filteredRow;
    });
  };

  const mapHeaders = (columns) => {
    const headers = {};
    columns.forEach((col) => {
      headers[col.dataField] = col.text;
    });
    return headers;
  };

  const exportToExcel = (data, fileName) => {
    const filteredData = filterDataForExport(data, columns);
    const headers = mapHeaders(columns);
    const worksheet = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(headers),
    });
    XLSX.utils.sheet_add_aoa(worksheet, [Object.values(headers)], {
      origin: "A1",
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Beboere");
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <>
      <Modal show={importCSV} size="lg">
        <ModalHeader>Importer fra CSV fil</ModalHeader>
        <ModalBody>
          <div className="mb-5">
            <Label>Kunde</Label>
            <Select
              options={
                isIterableArray(customers) &&
                Object.values(customers).map((customer) => ({
                  value: customer.customer_id,
                  label: customer.customer_name,
                }))
              }
              onChange={({ value }) => {
                loadCustomerBuildings(value);
                setImportCustomer(value);
              }}
            />
            <Label>Bygning</Label>
            <Select
              options={
                isIterableArray(customerBuildings) &&
                Object.values(customerBuildings).map((building) => ({
                  value: building.building_id,
                  label: building.building_name,
                }))
              }
              onChange={({ value }) => {
                setImportBuilding(value);
              }}
            />

            <Label>Delimiter</Label>
            <Input
              value={importDelimiter}
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={(e) => setImportDelimiter(e.target.value)}
            />
            <div style={{ paddingTop: "15px" }}>
              <Input type="file" onChange={handleImportClick} />
              {importMessage && (
                <Alert color="Warning">
                  {(importMessage ?? "").split("\n").map((str) => (
                    <p>{str}</p>
                  ))}
                </Alert>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => {
              setImportCsv(false);
              setImportMessage(null);
            }}
          >
            Avbryt
          </Button>
        </ModalFooter>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Bekrefte sletting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{modalMessage}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={() => setShowModal(false)}>
            Avbryt
          </Button>
          <Button color="primary" onClick={handleConfirmDelete}>
            Bekrefte
          </Button>
        </Modal.Footer>
      </Modal>
      <ToolkitProvider
        keyField="resident_id"
        data={filtered ?? []}
        columns={columns}
        length={filtered?.length ?? 0}
        exportCSV={{
          fileName: "beboere.csv",
          ignoreFooter: true,
          onlyExportFiltered: true,
          exportAll: true,
          onExport: (csvData) => {
            exportToCSV(csvData, "beboere.csv");
          },
        }}
      >
        {(props) => (
          <div>
            <Row>
              <Col style={{ minWidth: "200px" }}>
                <Input
                  type="search"
                  autoFocus
                  placeholder="Filter"
                  aria-label="Filter"
                  className="rounded-pill search-input"
                  value={filter}
                  onChange={({ target }) => setFilter(target.value)}
                />
                <FormText
                  className="ml-4"
                  muted
                >{`${filtered?.length} / ${residents?.length} Beboere`}</FormText>
              </Col>
              <Col xs="auto">
                <MyExportCSV {...props.csvProps} />
              </Col>
            </Row>
            <Button
              onClick={() => {
                history.push(`/resident/new`);
              }}
              className="m-2 fs--1 rounded-pill"
              size="sm"
              color="outline-primary"
            >
              <FontAwesomeIcon icon="plus" />
              {" Legg til"}
            </Button>
            <Button
              onClick={() => {
                handleDelete();
              }}
              color="danger"
              size="sm"
              className="m-2 fs--1 rounded-pill"
              disabled={!selected?.length > 0}
            >
              Slett
            </Button>
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                sizePerPage: 300,
                totalSize: isIterableArray(filtered) && filtered.length,
              })}
            >
              {({ paginationProps, paginationTableProps }) => {
                const lastIndex =
                  paginationProps.page * paginationProps.sizePerPage;

                return (
                  <>
                    <Nav tabs>
                      <NavItem className="cursor-pointer">
                        <NavLink
                          active={activeTab === "list"}
                          onClick={() => setActiveTab("list")}
                        >
                          Liste
                        </NavLink>
                      </NavItem>
                      <NavItem className="cursor-pointer">
                        <NavLink
                          active={activeTab === "map"}
                          onClick={() => setActiveTab("map")}
                        >
                          Kart
                        </NavLink>
                      </NavItem>
                    </Nav>
                    {activeTab === "list" && (
                      <div className="table-responsive  mb-4">
                        <BootstrapTable
                          {...props.baseProps}
                          hover
                          bordered={false}
                          classes="table-dashboard table-striped table-sm fs--1 border-bottom border-left border-right border-200"
                          rowClasses="btn-reveal-trigger border-top border-200 cursor-pointer"
                          headerClasses="bg-200 text-900 border-y border-200"
                          rowEvents={tableOptions}
                          defaultSorted={[{ dataField: "name", order: "asc" }]}
                          {...paginationTableProps}
                          selectRow={selectRow}
                        />
                      </div>
                    )}
                    {activeTab === "list" &&
                      isIterableArray(filtered) &&
                      filtered.length > 20 && (
                        <Row noGutters className="px-1 py-3 flex-center">
                          <Col xs="auto">
                            <Button
                              color="falcon-default"
                              size="sm"
                              onClick={handlePrevPage(paginationProps)}
                              disabled={paginationProps.page === 1}
                            >
                              <FontAwesomeIcon icon="chevron-left" />
                            </Button>
                            {getPaginationArray(
                              paginationProps.totalSize,
                              paginationProps.sizePerPage
                            ).map((pageNo) => (
                              <Button
                                color={
                                  paginationProps.page === pageNo
                                    ? "falcon-primary"
                                    : "falcon-default"
                                }
                                size="sm"
                                className="ml-2"
                                onClick={() =>
                                  paginationProps.onPageChange(pageNo)
                                }
                                key={pageNo}
                              >
                                {pageNo}
                              </Button>
                            ))}
                            <Button
                              color="falcon-default"
                              size="sm"
                              className="ml-2"
                              onClick={handleNextPage(paginationProps)}
                              disabled={lastIndex >= paginationProps.totalSize}
                            >
                              <FontAwesomeIcon icon="chevron-right" />
                            </Button>
                          </Col>
                        </Row>
                      )}
                    {activeTab === "map" && (
                      <div>
                        <AddressMap
                          addresses={Object.values(
                            residents
                              .map((value) => {
                                return {
                                  address:
                                    (value.street ?? "") +
                                    " " +
                                    (value.zip ?? "") +
                                    " " +
                                    (value.city ?? ""),
                                  name:
                                    value.first_name + " " + value.last_name,
                                };
                              })
                              .reduce((acc, curr) => {
                                if (!acc[curr.address]) {
                                  acc[curr.address] = {
                                    address: curr.address,
                                    name: curr.name,
                                  };
                                } else {
                                  acc[curr.address].name = `${
                                    acc[curr.address].name
                                  } , ${curr.name}`;
                                }
                                return acc;
                              }, {})
                          )}
                        />
                      </div>
                    )}
                  </>
                );
              }}
            </PaginationProvider>
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export default Residents;
