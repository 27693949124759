import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import { useAuthContext } from "../../context/authContext/authContext";
import PostBoxes from "./postboxes/PostBoxes";
import Buildings from "./buildings/Buildings";
import Residents from "./residents/Residents";
import Customers from "./customers/Customers";
import Users from "./users/Users";
import { isIterableArray } from "../../helpers/utils";
import { AppContext } from "../../context/appContext/appContext";
import {
  fetchBoxes,
  fetchBuildings,
  fetchCustomers,
  fetchResidents,
  fetchUsers,
} from "../../data/dataFetch";

const Home = () => {
  const { authenticationCode } = useAuthContext();

  const { activeHomeTab, setActiveHomeTab } = useContext(AppContext);
  const [boxes, setBoxes] = useState(null);
  const [buildings, setBuildings] = useState(null);
  const [residents, setResidents] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [users, setUsers] = useState(null);
  const [reloadResidents, setReloadResidents] = useState(false);

  useEffect(() => {
    const loadBoxes = async () => {
      setBoxes(await fetchBoxes());
    };
    const loadBuildings = async () => {
      setBuildings(await fetchBuildings());
    };

    const loadCustomers = async () => {
      setCustomers(await fetchCustomers());
    };
    const loadUsers = async () => {
      setUsers(await fetchUsers());
    };
    loadBoxes();
    loadBuildings();
    loadResidents();
    loadCustomers();
    loadUsers();
  }, [authenticationCode]);

  const loadResidents = async () => {
    const data = await fetchResidents();
    setResidents(data);
  };

  useEffect(() => {
    if (reloadResidents) {
      loadResidents();
      setReloadResidents(false);
    }
  }, [reloadResidents, loadResidents]);

  useEffect(() => {
    if (isIterableArray(residents) && isIterableArray(boxes)) {
      const hashedBoxes = {};
      boxes.forEach((b) => {
        hashedBoxes[b.box_id] = b;
      });
      residents.forEach((r) => {
        r.full_name = r.first_name + " " + r.last_name;
        r.resident_user_id = r.resident_id + "-" + r.user_id;
        r.box = hashedBoxes[r.box_id];
      });
    }
  }, [residents, boxes]);

  return (
    <Card>
      <Nav tabs>
        <NavItem className="cursor-pointer">
          <NavLink
            active={activeHomeTab === "Postboxes"}
            onClick={() => setActiveHomeTab("Postboxes")}
          >
            {"Postkasser: " + (boxes?.length ?? 0)}
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            active={activeHomeTab === "Buildings"}
            onClick={() => setActiveHomeTab("Buildings")}
          >
            {"Bygninger: " + (buildings?.length ?? 0)}
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            active={activeHomeTab === "Residents"}
            onClick={() => setActiveHomeTab("Residents")}
          >
            {"Beboere: " + (residents?.length ?? 0)}
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            active={activeHomeTab === "Customers"}
            onClick={() => setActiveHomeTab("Customers")}
          >
            {"Kunder: " + (customers?.length ?? 0)}
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            active={activeHomeTab === "Users"}
            onClick={() => setActiveHomeTab("Users")}
          >
            {"Bruker: " + (users?.length ?? 0)}
          </NavLink>
        </NavItem>
      </Nav>
      <CardBody>
        {activeHomeTab === "Postboxes" && <PostBoxes boxes={boxes} />}
        {activeHomeTab === "Buildings" && <Buildings buildings={buildings} />}
        {activeHomeTab === "Residents" && (
          <Residents
            residents={residents}
            setResidents={setResidents}
            onResidentsChange={loadResidents}
          />
        )}
        {activeHomeTab === "Customers" && <Customers customers={customers} />}
        {activeHomeTab === "Users" && <Users users={users} />}
      </CardBody>
    </Card>
  );
};

export default Home;
